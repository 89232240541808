<template>
  <div>
    <vs-card>
      <h4 class="mb-2 ml-5 mt-2">Please fill the derma specialist details</h4>
      <h4 class="mb-2 ml-5 mt-6" style="font-weight: 600">
        Derma Specialist owner details
      </h4>
      <form autocomplete="off">
        <vs-row
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="left"
          vs-w="12"
        >
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('basic.firstName') &&
                  franchiseData.firstName != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('basic.firstName') ? true : false"
                v-validate="'required'"
                name="firstName"
                data-vv-as="first name"
                label="First Name"
                class="w-full"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.firstName')"
                v-model="franchiseData.firstName"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('basic.lastName') &&
                  franchiseData.lastName != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('basic.lastName') ? true : false"
                v-validate="'required'"
                name="lastName"
                data-vv-as="last name"
                label="Last Name"
                class="w-full"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.lastName')"
                v-model="franchiseData.lastName"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('basic.email') && franchiseData.email != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('basic.email') ? true : false"
                v-validate="'required|email'"
                name="email"
                data-vv-as="email"
                label="Email"
                class="w-full"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.email')"
                id="emailAId"
                v-model="franchiseData.email"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                type="password"
                :success="
                  !errors.first('basic.password') &&
                  franchiseData.password != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('basic.password') ? true : false"
                v-validate="'required'"
                name="password"
                data-vv-as="password"
                label="Password"
                class="w-full"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.password')"
                id="pw"
                autocomplete="new-password"
                v-model="franchiseData.password"
              />
            </div>
          </vs-col>
        </vs-row>
        <h4 class="mb-2 ml-5 mt-6" style="font-weight: 600">
          Derma Specialist business details
        </h4>
        <vs-row
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="left"
          vs-w="12"
        >
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('basic.contactNumber') &&
                  franchiseData.contactNumber != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('basic.contactNumber') ? true : false"
                v-validate="'required'"
                name="contactNumber"
                data-vv-as="contact number"
                label="Contact Number"
                class="w-full"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.contactNumber')"
                v-model="franchiseData.contactNumber"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('basic.companyName') &&
                  franchiseData.companyName != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('basic.companyName') ? true : false"
                v-validate="'required'"
                name="companyName"
                data-vv-as="company name"
                label="Company Name"
                class="w-full"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.companyName')"
                v-model="franchiseData.companyName"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('basic.franchiseBusinessName') &&
                  franchiseData.franchiseBusinessName != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="
                  errors.first('basic.franchiseBusinessName') ? true : false
                "
                name="franchiseBusinessName"
                :danger-text="errors.first('basic.franchiseBusinessName')"
                data-vv-as="clinic name"
                label="Franchise Business Name (optional)"
                class="w-full"
                data-vv-scope="basic"
                v-model="franchiseData.franchiseBusinessName"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="!errors.first('basic.abn') && franchiseData.abn != ''"
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('basic.abn') ? true : false"
                v-validate="'required'"
                name="abn"
                :danger-text="errors.first('basic.abn')"
                data-vv-as="ABN"
                label="ABN"
                class="w-full"
                data-vv-scope="basic"
                v-model="franchiseData.abn"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <!--              <vs-input-->
              <!--              :success="!errors.first('basic.streetAddress') && franchiseData.streetAddress!=''"-->
              <!--              val-icon-success="done"-->
              <!--              val-icon-danger="error"-->
              <!--              :danger="(errors.first('basic.streetAddress') ? true : false)"-->
              <!--              v-validate="'required'"-->
              <!--              name="streetAddress"-->
              <!--              :danger-text="errors.first('basic.streetAddress')"-->
              <!--              data-vv-as="street address"-->
              <!--              label="Head Office Street Address"-->
              <!--              class="w-full"-->
              <!--              data-vv-scope="basic"-->
              <!--              v-model="franchiseData.streetAddress"/>-->
              <label class="vs-input--label">Franchise Street Address</label>
              <div
                class="
                  vs-component vs-con-input-label vs-input
                  w-full
                  vs-input-primary
                  is-label-placeholder
                "
                style="margin-top: -1px"
                :class="{
                  'input-icon-validate-danger':
                    errors.first('displayAddress') ||
                    (franchiseData.address.displayAddress && !coordinates[0]) ||
                    checkDisplayAddress != franchiseData.address.displayAddress,
                  'input-icon-validate-success':
                    coordinates[0] &&
                    checkDisplayAddress == franchiseData.address.displayAddress,
                }"
              >
                <div class="vs-con-input">
                  <input
                    type="text"
                    placeholder
                    ref="autocomplete"
                    v-validate="'required'"
                    data-vv-validate-on="blur"
                    data-vv-as="Address"
                    name="displayAddress"
                    v-model="franchiseData.address.displayAddress"
                    class="vs-inputx vs-input--input normal"
                    :class="{ hasValue: franchiseData.address.displayAddress }"
                    style="border: 1px solid rgba(0, 0, 0, 0.2)"
                  />
                  <!--                <span class="input-span-placeholder vs-input--placeholder normal normal vs-placeholder-label">-->
                  <!--                            Select the address</span>-->
                  <span class="input-icon-validate vs-input--icon-validate">
                    <i
                      v-if="
                        coordinates[0] &&
                        checkDisplayAddress ==
                          franchiseData.address.displayAddress
                      "
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >done</i
                    >
                    <i
                      v-else-if="
                        errors.first('displayAddress') ||
                        (franchiseData.address.displayAddress &&
                          !coordinates[0]) ||
                        checkDisplayAddress !=
                          franchiseData.address.displayAddress
                      "
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >error</i
                    >
                  </span>
                </div>
                <span>
                  <div class="con-text-validation vs-input--text-validation">
                    <span
                      class="
                        span-text-validation span-text-validation-success
                        vs-input--text-validation-span
                      "
                    ></span>
                  </div>
                </span>
                <span class="text-danger" style="font-size: 0.75em">{{
                  errors.first("displayAddress")
                }}</span>
                <!-- <span class="text-danger" style="font-size: 0.75em">
                  sdfasdfasdfds
                </span> -->
              </div>
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('basic.postalAddress') &&
                  franchiseData.postalAddress != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('basic.postalAddress') ? true : false"
                name="postalAddress"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.postalAddress')"
                data-vv-as="postal address"
                label="Head Office Postal Address (optional)"
                class="w-full"
                v-model="franchiseData.postalAddress"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('basic.bestContactName') &&
                  franchiseData.bestContactName != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                data-vv-scope="basic"
                :danger="errors.first('basic.bestContactName') ? true : false"
                v-validate="'required'"
                :danger-text="errors.first('basic.bestContactName')"
                name="bestContactName"
                data-vv-as="best contact name"
                label="Best Contact Name"
                class="w-full"
                v-model="franchiseData.bestContactName"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('basic.bestContactEmail') &&
                  franchiseData.bestContactEmail != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('basic.bestContactEmail') ? true : false"
                v-validate="'required|email'"
                data-vv-scope="basic"
                name="bestContactEmail"
                :danger-text="errors.first('basic.bestContactEmail')"
                data-vv-as="best contact email"
                label="Best Contact Email"
                class="w-full"
                v-model="franchiseData.bestContactEmail"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('basic.bestContactNumber') &&
                  franchiseData.bestContactNumber != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('basic.bestContactNumber') ? true : false"
                v-validate="'required'"
                name="bestContactNumber"
                data-vv-as="best contact number"
                label="Best Contact Number"
                class="w-full"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.bestContactNumber')"
                v-model="franchiseData.bestContactNumber"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-checkbox
                ref="centralise"
                v-model="franchiseData.wantCentralisebilling"
                class="mt-6"
                >Do you require centralised billing ?</vs-checkbox
              >
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :danger="errors.first('basic.invoiceEmail') ? true : false"
                :success="
                  !errors.first('basic.invoiceEmail') &&
                  franchiseData.invoiceEmail != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                v-if="franchiseData.wantCentralisebilling"
                v-validate="'required_if:centralise,true|email'"
                data-vv-validate-on="blur"
                data-vv-as="invoice email address"
                data-vv-scope="basic"
                label="Invoice Email Address"
                name="invoiceEmail"
                placeholder="Invoice Email Address"
                v-model="franchiseData.invoiceEmail"
                class="w-full mt-6"
              />
              <span class="text-danger text-sm">{{
                errors.first("basic.invoiceEmail")
              }}</span>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-align="left" vs-w="12">
            <div class="w-full m-5">
              <vs-button
                size="normal"
                @click="saveData"
                icon-pack="feather"
                class="mr-2"
                >Create Derma Specialist</vs-button
              >
            </div>
          </vs-col>
        </vs-row>
      </form>
    </vs-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data: () => ({
    franchiseData: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      contactNumber: "",
      companyName: "",
      franchiseBusinessName: "",
      abn: "",
      address: {
        displayAddress: "",
        state: "",
        suburb: "",
        postCode: "",
        geometry: {
          type: "Point",
          coordinates: [],
        },
      },
      postalAddress: "",
      bestContactName: "",
      bestContactEmail: "",
      bestContactNumber: "",
      wantCentralisebilling: false,
      invoiceEmail: "",
      status: "Active",
      userType: "dermaSpecialist",
    },
    checkDisplayAddress: "",
    coordinates: [],
  }),
  methods: {
    ...mapActions("clinic", ["createClinicBasic", "removeClinicImages"]),
    async saveData() {
      let self = this;
      if (self.franchiseData.address.displayAddress === "") {
        self.errors.add({
          field: "displayAddress",
          msg: "The franchise street address field is required",
        });
      }

      let isValid = await this.$validator.validateAll("basic");

      if (self.franchiseData.address.displayAddress === "") {
        self.errors.add({
          field: "displayAddress",
          msg: "The franchise street address field is required",
        });
        return false;
      }

      if (isValid) {
        let formData = this.franchiseData;
        formData.emailVerifiedAt = new Date().toISOString();

        if (
          this.$store.state.AppActiveUser.userType === "superAdmin" ||
          this.$store.state.AppActiveUser.userType === "admin"
        ) {
          formData.hasApprovedByAdmin = true;
        }

        if (formData.address.displayAddress === "") {
          self.errors.add({
            field: "displayAddress",
            msg: "The franchise street address field is required",
          });
          return false;
        }

        if (
          formData.address.geometry.coordinates.length === 0 ||
          this.checkDisplayAddress !== formData.address.displayAddress
        ) {
          self.errors.add({
            field: "displayAddress",
            msg: "Please choose address from google address suggestion so that  it will provide us coordinates",
          });
          return false;
        }

        await this.createClinicBasic(formData)
          .then((res) => {
            self.$vs.notify({
              title: "Derma Specialist Created",
              text: "Derma Specialist created Successfully.",
              color: "success",
            });
            self.$router.push({ name: "SuperAdminDermaSpecialist" });
          })
          .catch((err) => {
            let message = "Failed to create franchise. Try again!";
            isValid = false;
            if (err.status === 422) {
              self.mapServerErrors(self, err, "basic");
            }
            if (err.status === 417) {
              if (err.data.title === "Invalid email") {
                self.errors.add({
                  field: "email",
                  msg: err.data.message,
                  scope: "basic",
                });
                message = "Invalid email";
              } else if (err.data.title === "Invalid ABN") {
                self.errors.add({
                  field: "abn",
                  msg: err.data.message,
                  scope: "basic",
                });
                message = "Invalid Abn";
              }
            }
            self.$vs.notify({
              title: "Failed",
              text: message,
              color: "danger",
            });
          });
      }
    },
    mapServerErrors(self, err, form = "") {
      let data = err.data.data.details;
      data.map(function (value, key) {
        self.errors.add({
          field: value.context.key,
          msg: value.message.replace(/"/g, ""),
        });
      });
    },
    place_changed(a) {
      // console.log(a)
    },
  },
  mounted() {
    this.autocomplete = new google.maps.places.Autocomplete(
      this.$refs.autocomplete,
      { types: ["geocode"], componentRestrictions: { country: "au" } }
    );
    this.autocomplete.addListener("place_changed", () => {
      let place = this.autocomplete.getPlace();
      this.errors.remove("displayAddress");
      let ac = place.address_components;
      this.checkDisplayAddress = place.formatted_address;
      let lat = place.geometry.location.lat();
      let lon = place.geometry.location.lng();
      this.franchiseData.address.displayAddress = place.formatted_address;
      this.coordinates[0] = lat;
      this.coordinates[0] = lat;
      this.coordinates[1] = lon;
      this.franchiseData.address.geometry.coordinates[0] = lon;
      this.franchiseData.address.geometry.coordinates[1] = lat;
      this.franchiseData.address.suburb =
        place.address_components[0].short_name;
      this.franchiseData.address.state = place.address_components[1].short_name;
    });
  },
};
</script>
